import { useNavigate } from "react-router-dom";

export const MSearch = () => {
  const navigate = useNavigate();
  const handleOnSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const search = formData.get("search");
    navigate(`/tim-kiem/${search}`);
    console.log(search);
  };
  return (
    <form onSubmit={handleOnSearch}>
      <label
        className="mb-2 text-sm font-medium text-gray-900 sr-only pl-3 "
        htmlFor="default-search"
      >
        Search
      </label>
      <div className="">
        <input
          className="lg:hidden block absolute  top-16 left-0 right-0 pl-3 py-2 bg-slate-500 transition"
          id="default-search"
          name="search"
          placeholder="Tìm kiếm"
          required
          type="search"
        ></input>
        <button
          className="text-black absolute end-0.5 bottom-2.5 top-16   font-medium rounded-lg text-sm px-4 py-2"
          type="submit"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </form>
  );
};
