import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Loading } from "../components/Loading";
import { MovieCard } from "../components/MovieCard";
import { Pagination } from "../components/Pagination";
import { getListMoviesByYear } from "../services/base";

export const MoviesByYear = () => {
  const [phim, setPhim] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const { year } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const pageParam = urlParams.get("page") || 1;
  const page = +pageParam || 1;

  useEffect(() => {
    getListMoviesByYear(year || "", +page)
      .then((data) => {
        setPhim(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, year]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{`Phim mới năm ${year} - Mê Phim 247`}</title>
        <meta
          content="Mê Phim 247 là website mang đến một trải nghiệm điện ảnh hoàn toàn mới lạ. Trang cung cấp đa dạng các thể loại phim lẻ, phim chiếu rạp full vietsub, chất lượng cao mới nhất với âm thanh tuyệt đối."
          name="description"
        />
        <link href={window.location.href} rel="canonical" />
      </Helmet>
      <h1 className="text-2xl font-bold mb-4">Phim mới năm {year}</h1>
      <div className="grid grid-cols-3 gap-4 md:grid-cols-4 lg:grid-cols-5 mb-4">
        {phim?.items?.map((item: any) => (
          <MovieCard data={item} key={item._id} />
        ))}
      </div>
      <Pagination
        page={phim?.params?.pagination?.currentPage}
        total={phim?.params?.pagination?.totalItems}
      />
    </>
  );
};
