import LogoImg from "../assets/icons/Logo.png";
export const Logo = () => {
  return (
    <a href="/">
      <img
        alt="Logo"
        className="w-40 h-12 object-contain"
        loading="eager"
        src={LogoImg}
      />
    </a>
  );
};
