import { useState } from "react";
import Headroom from "react-headroom";
import {
  CATAGORIES,
  MOVIE_CATAGORIES,
  getMovieCatagoryTitle,
} from "../consts/CATAGORIES";
import { COUNTRIES, getCountryTitle } from "../consts/COUNTRIES";
import { CLink as Link } from "./CLink";
import DropdownMenu from "./DropdownMenu";
import { Logo } from "./Logo";
import { MSearch } from "./MSearch";
import { Search } from "./Search";

export const Header = () => {
  const menuItemClass = "hover:text-gray-300 font-bold text-base";
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };
  const [isOpen, setisOpen] = useState(false);
  const handleisOpen = () => {
    setisOpen(!isOpen);
  };

  const Navbar = (
    <>
      <div className="lg:hidden block absolute  top-16 left-0 right-0 bg-slate-900 transition">
        <ul className="text-start text-xl pl-3">
          {[CATAGORIES.PHIM_MOI, CATAGORIES.PHIM_LE, CATAGORIES.PHIM_BO].map(
            (catagory) => (
              <Link
                className=" text-white font-bold"
                key={catagory}
                reloadDocument
                to={`/the-loai/${catagory}`}
              >
                <li className="my-2 py-2 border-b border-slate-800 hover:bg-slate-800 hover:rounded">
                  {CATAGORIES.getTitle(catagory)}
                </li>
              </Link>
            ),
          )}
        </ul>
        <ul className="text-start  pl-3 border-b border-slate-800 ">
          <div className="my-2 py-2 text-xl text-white font-bold ">
            Thể loại
          </div>
          <li className="grid grid-cols-2 text-base mb-2">
            {[
              ...Object.values(MOVIE_CATAGORIES),
              CATAGORIES.HOAT_HINH,
              CATAGORIES.TV_SHOW,
            ].map((catagory) => (
              <Link
                className=" text-gray-500  "
                key={catagory}
                reloadDocument
                to={`/the-loai/${catagory}`}
              >
                - Phim{" "}
                {getMovieCatagoryTitle(catagory) ||
                  CATAGORIES.getTitle(catagory)}
              </Link>
            ))}
          </li>
        </ul>
        <ul className="text-start  pl-3 border-b border-slate-800 ">
          <div className="my-2 py-2 text-xl text-white font-bold ">
            Quốc gia
          </div>
          <li className="grid grid-cols-2 text-base mb-2">
            {Object.values(COUNTRIES).map((country) => (
              <Link
                className=" text-gray-500"
                key={country}
                reloadDocument
                to={`/quoc-gia/${country}`}
              >
                - Phim {getCountryTitle(country)}
              </Link>
            ))}
          </li>
        </ul>
      </div>
    </>
  );

  return (
    <Headroom className="w-full mb-4">
      <div className="w-full flex flex-col items-center justify-center drop-shadow-md bg-white relative z-10">
        <header className="max-w-screen-xl w-full p-2 flex justify-between">
          <div className="items-center justify-center gap-8 hidden lg:flex">
            <Logo />
            {[CATAGORIES.PHIM_MOI, CATAGORIES.PHIM_LE, CATAGORIES.PHIM_BO].map(
              (catagory) => (
                <Link
                  className={menuItemClass}
                  key={catagory}
                  reloadDocument
                  to={`/the-loai/${catagory}`}
                >
                  {CATAGORIES.getTitle(catagory)}
                </Link>
              ),
            )}

            <DropdownMenu label="Thể Loại">
              {[
                ...Object.values(MOVIE_CATAGORIES),
                CATAGORIES.HOAT_HINH,
                CATAGORIES.TV_SHOW,
              ].map((catagory) => (
                <Link
                  className="block text-black hover:text-gray-300"
                  key={catagory}
                  reloadDocument
                  to={`/the-loai/${catagory}`}
                >
                  Phim{" "}
                  {getMovieCatagoryTitle(catagory) ||
                    CATAGORIES.getTitle(catagory)}
                </Link>
              ))}
            </DropdownMenu>
            <DropdownMenu label="Quốc Gia">
              {Object.values(COUNTRIES).map((country) => (
                <Link
                  className="block text-black hover:text-gray-300"
                  key={country}
                  reloadDocument
                  to={`/quoc-gia/${country}`}
                >
                  Phim {getCountryTitle(country)}
                </Link>
              ))}
            </DropdownMenu>
          </div>
          <div className="hidden lg:flex">
            <Search />
          </div>
          {/* MOBILE MENU*/}
          <div className=" lg:hidden  flex">
            <div>{click && Navbar}</div>
            <button className="block transition" onClick={handleClick}>
              {click ? (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 18 18 6M6 6l12 12"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className=" lg:hidden  flex">
            <Logo />
          </div>
          <div className=" lg:hidden  flex">
            <div>{isOpen && <MSearch />}</div>
            <button className="block  transition" onClick={handleisOpen}>
              {isOpen ? (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 18 18 6M6 6l12 12"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
          </div>
        </header>
      </div>
    </Headroom>
  );
};
