import { useNavigate } from "react-router-dom";

export const Search = () => {
  const navigate = useNavigate();
  const handleOnSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const search = formData.get("search");
    navigate(`/tim-kiem/${search}`);
    console.log(search);
  };
  return (
    <form onSubmit={handleOnSearch}>
      <label
        className="mb-2 text-sm font-medium text-gray-900 sr-only"
        htmlFor="default-search"
      >
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-4 h-4 text-gray-500"
            fill="none"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        </div>
        <input
          className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          id="default-search"
          name="search"
          placeholder="Tìm kiếm"
          required
          type="search"
        />
        <button
          className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
          type="submit"
        >
          Tìm
        </button>
      </div>
    </form>
  );
};
