import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../components/Loading";
import { MovieCard } from "../components/MovieCard";
import { Pagination } from "../components/Pagination";
import { searchMovies } from "../services/base";

export const SearchPage = () => {
  const [phim, setPhim] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const { keyword } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const pageParam = urlParams.get("page") || 1;
  const page = +pageParam || 1;

  useEffect(() => {
    searchMovies(keyword || "", +page)
      .then((data) => {
        setPhim(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, keyword]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="grid grid-cols-3 gap-4 md:grid-cols-4 lg:grid-cols-5 mb-4">
        {phim?.items?.map((item: any) => (
          <MovieCard data={item} key={item._id} />
        ))}
      </div>
      <Pagination
        page={phim?.params?.pagination?.currentPage}
        total={phim?.params?.pagination?.totalItems}
      />
    </>
  );
};
