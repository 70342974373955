import { Outlet } from "react-router-dom";
import adsData from "./ads.json";
import { AddsPlaceholder } from "./components/Ads/AddsPlaceholder";
import { AdsBottom } from "./components/Ads/AdsBottom";
import { AdsPopup } from "./components/Ads/AdsPopup";
import { ByYear } from "./components/ByYear";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { AdsData } from "./type/type";

const getAdsData: AdsData = {
  "BL Banner 1": adsData["BL Banner 1"],
  "BL Banner 2": adsData["BL Banner 2"],
};

export const App = () => {
  return (
    <div>
      <div className="w-full flex flex-col justify-center items-center">
        <Header />
        <main className="w-full max-w-screen-xl">
          <div className="flex flex-col gap-1 mb-4 place-items-center sm:flex-row">
            {Object.keys(getAdsData).map((key, index) => (
              <AddsPlaceholder
                key={index}
                linkTo={getAdsData[key].adsUrl.url}
                scr={getAdsData[key].bannerImg.url}
              />
            ))}
          </div>
          <ByYear />
          <div className="overflow-hidden w-full">
            <Outlet />
          </div>
        </main>
        <Footer />
      </div>
      <AdsBottom />
      <AdsPopup />
    </div>
  );
};
