export const COUNTRIES = {
  AN_DO: "an-do",
  AU_MY: "au-my",
  DAI_LOAN: "dai-loan",
  HAN_QUOC: "han-quoc",
  HONG_KONG: "hong-kong",
  INDONESIA: "indonesia",
  NHAT_BAN: "nhat-ban",
  PHILIPPINES: "philippines",
  SINGAPORE: "singapore",
  THAI_LAN: "thai-lan",
  TRUNG_QUOC: "trung-quoc",
};

export const getCountryTitle = (country: string) => {
  switch (country) {
    case COUNTRIES.AN_DO:
      return "Ấn Độ";
    case COUNTRIES.AU_MY:
      return "Âu Mỹ";
    case COUNTRIES.DAI_LOAN:
      return "Đài Loan";
    case COUNTRIES.HAN_QUOC:
      return "Hàn Quốc";
    case COUNTRIES.HONG_KONG:
      return "Hồng Kông";
    case COUNTRIES.INDONESIA:
      return "Indonesia";
    case COUNTRIES.NHAT_BAN:
      return "Nhật Bản";
    case COUNTRIES.PHILIPPINES:
      return "Philippines";
    case COUNTRIES.SINGAPORE:
      return "Singapore";
    case COUNTRIES.THAI_LAN:
      return "Thái Lan";
    case COUNTRIES.TRUNG_QUOC:
      return "Trung Quốc";
    default:
      break;
  }
};
