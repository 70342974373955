import { useEffect, useState } from "react";
import LeftIcon from "../assets/icons/left.svg";
import RightIcon from "../assets/icons/right.svg";
import { CATAGORIES } from "../consts/CATAGORIES";
import { getListMoviesByCatagory } from "../services/base";
import { CLink as Link } from "./CLink";
import { Loading } from "./Loading";
import { MovieCard } from "./MovieCard";

export const Carousel = ({ catagory }: any) => {
  const [current, setCurrent] = useState(0);
  const [phim, setPhim] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const data = phim?.items || {};
  const length = data.length;

  const nextSlide = () => {
    setCurrent((current + 1) % length);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  useEffect(() => {
    getListMoviesByCatagory(catagory)
      .then((data) => {
        setPhim(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [catagory]);

  if (loading) {
    return <Loading />;
  }

  if (!Array.isArray(data) || data.length <= 0) {
    return null;
  }

  return (
    <section className="mb-10">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-bold">{CATAGORIES.getTitle(catagory)}</h2>
        <div className="flex gap-2">
          <Link reloadDocument to={`/the-loai/${catagory}`}>
            Xem thêm
          </Link>
          <button onClick={prevSlide}>
            <img alt="prev" className="w-4 h-4" src={LeftIcon} />
          </button>
          <button onClick={nextSlide}>
            <img alt="next" className="w-4 h-4" src={RightIcon} />
          </button>
        </div>
      </div>
      <div className="relative flex items-center justify-center overflow-hidden">
        <div
          className="flex gap-4 transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${current * (100 / length / 3)}%)` }}
        >
          {data.map((data, index) => {
            return (
              <div className="w-44" key={index}>
                <MovieCard data={data} key={data._id} />
              </div>
            );
          })}
          {data.map((data, index) => {
            return (
              <div className="w-44" key={length + index}>
                <MovieCard data={data} key={data._id} />
              </div>
            );
          })}
          {data.map((data, index) => {
            return (
              <div className="w-44" key={length * 2 + index}>
                <MovieCard data={data} key={data._id} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
