import { CATFISH_LINK } from "../../configs";
import { CLink } from "../CLink";

export const AddsPlaceholder = ({
  className,
  linkTo,
  scr,
}: {
  className?: string;
  linkTo?: string;
  scr: string;
}) => {
  return (
    <div
      className={`w-full h-16.5 flex flex-1 items-center justify-center font-extrabold text-gray-500 ${className}`}
    >
      <CLink target="_blank" to={linkTo ?? CATFISH_LINK}>
        <img loading="eager" src={scr} width={1024} />
      </CLink>
    </div>
  );
};
