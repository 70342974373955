import "@dotlottie/player-component";
import loading from "../assets/lottie/loading.lottie";

export const Loading = () => {
  return (
    <div className="h-40 w-full">
      <dotlottie-player
        autoplay
        loop
        src={loading}
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
};
