import { useState } from "react";
import adsData from "../../ads.json";
import { AdsData } from "../../type/type";
import { CLink } from "../CLink";
import { AddsPlaceholder } from "./AddsPlaceholder";

export const AdsBottom = () => {
  const [isAdsVisible, setIsAdsVisible] = useState(true);
  const getAdsData: AdsData = {
    "AB Banner 1": adsData["AB Banner 1"],
    "AB Banner 2": adsData["AB banner 2"],
    "AB Banner 3": adsData["AB banner 3"],
    "EP Banner 1": adsData["EP banner 1"],
    "EP Banner 2": adsData["EP Banner 2"],
    "EP Banner 3": adsData["EP Banner 3"],
  };

  return (
    <div className={`${isAdsVisible ? "fixed" : "hidden"} bottom-0 w-full`}>
      <button className="block m-auto bg-slate-900 text-white px-2">
        <CLink onClick={() => setIsAdsVisible(false)} to="#">
          Đóng quảng cáo
        </CLink>
      </button>

      <div className="grid gap-1 justify-items-center lg:grid-rows-3 lg:grid-flow-col xl:grid-cols-3 xl:grid-rows-none xl:grid-flow-row 2xl:grid-cols-5">
        {Object.keys(getAdsData).map((key, index) => (
          <AddsPlaceholder
            className={index === 0 || index === 2 ? "hidden lg:block" : ""}
            key={index}
            linkTo={getAdsData[key].adsUrl.url}
            scr={getAdsData[key].bannerImg.url}
          />
        ))}
      </div>
    </div>
  );
};
