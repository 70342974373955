import { useEffect } from "react";

export const FBComment = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v19.0";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="fb-comments"
      data-href={window.location.href}
      data-numposts="5"
      data-width=""
    ></div>
  );
};
