import { CLink as Link } from "./CLink";

const currentYear = new Date().getFullYear();
const years: any = [];
for (let year = 2021; year <= currentYear; year++) {
  years.push(year);
}

export const ByYear = () => {
  return (
    <section className="mb-10">
      <h2 className="text-xl font-semibold mb-4">Năm phát hành</h2>
      <div className="grid grid-cols-5 gap-2 md:grid-cols-8 lg:grid-cols-15">
        {years.toReversed().map((year: any) => (
          <Link
            className="bg-slate-300 font-bold text-center py-1 text-lg"
            key={year}
            reloadDocument
            to={`/nam/${year}`}
          >
            {year}
          </Link>
        ))}
      </div>
    </section>
  );
};
