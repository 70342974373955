import React, { useState } from "react";
import ChevronDownIcon from "../assets/icons/down.svg";

interface DropdownMenuProps {
  children?: React.ReactNode;
  label: React.ReactNode | string;
}

function DropdownMenu(props: DropdownMenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="relative inline-block text-left"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <p className="hover:opacity-50 font-bold text-base cursor-pointer flex gap-1">
        {typeof props.label === "string" ? (
          <>
            {props.label} <img className="w-4" src={ChevronDownIcon} />
          </>
        ) : (
          props.label
        )}
      </p>

      {isOpen && (
        <div className="origin-top-right absolute left-0 w-80 rounded-md shadow-lg z-10">
          <div className="rounded-md bg-white shadow-xs">
            <div className="p-4 grid grid-cols-2 gap-2">{props.children}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownMenu;
