function generatePageArray(page: any, max: any) {
  let start = page - 2;
  if (start < 1) start = 1;
  if (start + 4 > max) start = max - 4;
  return Array.from({ length: 5 }, (_, i) => start + i);
}

export const Pagination = ({ page, total }: any) => {
  return (
    <nav aria-label="Page navigation example">
      <ul className="flex items-center -space-x-px h-8 text-sm">
        {
          <li>
            <a
              className={`flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${page <= 1 ? "opacity-50 pointer-events-none" : ""}`}
              href={`?page=${page - 1}`}
            >
              <span className="sr-only">Previous</span>
              <svg
                aria-hidden="true"
                className="w-2.5 h-2.5 rtl:rotate-180"
                fill="none"
                viewBox="0 0 6 10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 1 1 5l4 4"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
            </a>
          </li>
        }
        {generatePageArray(page, total).map((i: number) =>
          i === page ? (
            <li key={i}>
              <a
                aria-current="page"
                className="z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                href={`?page=${i}`}
              >
                {i}
              </a>
            </li>
          ) : (
            <li key={i}>
              <a
                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                href={`?page=${i}`}
              >
                {i}
              </a>
            </li>
          ),
        )}
        <li>
          <a
            className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${page >= total ? "opacity-50 pointer-events-none" : ""}`}
            href={`?page=${page + 1}`}
          >
            <span className="sr-only">Next</span>
            <svg
              aria-hidden="true"
              className="w-2.5 h-2.5 rtl:rotate-180"
              fill="none"
              viewBox="0 0 6 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m1 9 4-4-4-4"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  );
};
