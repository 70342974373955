import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import ReactHlsPlayer from "react-hls-player";
import { useParams } from "react-router-dom";
import { AdsVideo } from "../components/Ads/AdsVideo";
import { Breadcrumb } from "../components/Breadcrumb";
import { FBComment } from "../components/FBComment";
import { ListEpisodes } from "../components/ListEpisodes";
import { Loading } from "../components/Loading";
import { MovieCardDetail } from "../components/MovieCard";
import {
  ALLOW_TO_PLAY_VIDEO,
  APP_DOMAIN_CDN_IMAGE,
  HOST_M3U8,
} from "../configs";
import { getMovieDetail } from "../services/base";
import pLoader from "../utils/FetchLoader";

export const WatchMovie = () => {
  const { episode, id } = useParams();
  const [movie, setMovie] = useState<any>({});
  const [seoOnPage, setSeoOnPage] = useState<any>({});
  const [currentEpisode, setCurrentEpisode] = useState<any>({});
  const [, setIsAdsVisible] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [loading, setLoading] = useState(true);
  const playerRef = useRef<HTMLVideoElement>(null);

  const Player = useMemo(() => {
    return +ALLOW_TO_PLAY_VIDEO ? (
      <ReactHlsPlayer
        autoPlay={false}
        className="my-4 h-[520px] bg-black"
        controls={true}
        hlsConfig={{
          pLoader: pLoader,
        }}
        playerRef={playerRef}
        src={
          currentEpisode?.link_m3u8 ||
          `${HOST_M3U8}/?link_m3u8=${currentEpisode?.link_m3u8}`
        }
        width="100%"
      />
    ) : (
      <div className="my-4 text-center flex items-center justify-center flex-col text-red-500 bg-slate-700 h-64 sm:h-[500px]">
        <h1 className="text-2xl font-bold">Video nguồn không hợp lệ</h1>
        <p className="mt-2">Vui lòng liên hệ Admin</p>
      </div>
    );
  }, [currentEpisode]);

  useEffect(() => {
    getMovieDetail(id || "")
      .then((data) => {
        const tempMovie = data?.pageProps?.data?.item;
        setMovie(tempMovie);
        const tempSEO = data?.pageProps?.data?.seoOnPage?.seoSchema;
        setSeoOnPage({
          "@context": "https://schema.org",
          "@type": "VideoObject",
          contentUrl: window.location.href,
          dateModified: tempSEO?.dateModified,
          datePublished: tempSEO?.datePublished,
          description: data?.pageProps?.data?.seoOnPage?.descriptionHead,
          name: data?.pageProps?.data?.seoOnPage?.titleHead,
          thumbnailUrl: `${APP_DOMAIN_CDN_IMAGE}/uploads/movies/${tempMovie?.thumb_url}`,
          uploadDate: tempSEO?.dateCreated,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (movie.episodes) {
      setCurrentEpisode(
        movie?.episodes?.[0]?.server_data?.find(
          (a: any) => (a.slug || a.name) === episode,
        ),
      );
    }
  }, [movie, episode]);

  const closeAd = () => {
    setIsAdsVisible(false);
    sessionStorage.setItem("lastClosed", String(Date.now()));
    setShowPlayer(true);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(seoOnPage)}</script>
        <meta
          content={`${APP_DOMAIN_CDN_IMAGE}/uploads/movies/${movie?.thumb_url}`}
          property="og:image"
        />
        <title>{`${seoOnPage?.name} - Tập ${currentEpisode?.name}`}</title>
        <meta
          content={seoOnPage?.description || movie?.origin_name}
          name="description"
        />
        <link href={window.location.href} rel="canonical" />
      </Helmet>
      <Breadcrumb
        current={`Tập ${currentEpisode?.name}`}
        parents={[{ link: `/phim/${movie?.slug}`, name: movie?.name }]}
      />
      {!showPlayer ? <AdsVideo onSkip={closeAd} /> : <>{Player}</>}
      <ListEpisodes current={episode} episodes={movie.episodes} id={id} />
      <MovieCardDetail movie={movie} />
      <FBComment />
    </div>
  );
};
