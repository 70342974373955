export const ListEpisodes = ({ current, episodes, id }: any) => {
  const renderServers = episodes
    ?.map((server: any) => {
      const renderButtons = server.server_data
        ?.map(
          (episode: any) =>
            Boolean(episode.link_embed) && (
              <a
                className={`text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-1 text-center me-2 mb-2 ${episode.slug === current ? "opacity-50 pointer-events-none" : ""}`}
                href={`/xem-phim/${id}/${episode.slug || episode.name}`}
                key={episode.link_embed}
                type="button"
              >
                Tập {episode.name}
              </a>
            ),
        )
        .filter(Boolean);
      if (!renderButtons.length) return null;
      return (
        <div key={server.server_name}>
          <h3 className="font-bold underline mb-4">{server.server_name}</h3>
          <div>{renderButtons}</div>
        </div>
      );
    })
    .filter(Boolean);
  if (!renderServers.length) return <p>Đang cập nhập ...</p>;
  return (
    <div>
      <p className="text-xl font-semibold mb-4">Chọn tập phim</p>
      {renderServers}
    </div>
  );
};
