import { API_PATH } from "../configs";
import { CATAGORIES, MOVIE_CATAGORIES } from "../consts/CATAGORIES";

const handleResponse = async (data: any) => {
  try {
    const items = data.pageProps.data.items.filter(
      (item: any) => item.year >= 2021,
    );
    return {
      ...data.pageProps.data,
      items,
    };
  } catch (error) {
    console.error("Error: ", error);
    return data?.pageProps?.data;
  }
};

export const getListMoviesByCatagory = async (catagory: string, page = 1) => {
  const requestOptions: any = {
    method: "GET",
    redirect: "follow",
  };

  const isTheLoai = Object.values(MOVIE_CATAGORIES).includes(catagory);

  return await fetch(
    `${API_PATH}/${isTheLoai ? "the-loai" : "danh-sach"}/${catagory}.json?slug=${catagory}&page=${page}`,
    requestOptions,
  )
    .then((response) => response.json())
    .then((result) => handleResponse(result))
    .catch((error) => console.log("error", error));
};

export const getListMoviesByCountry = async (country: string, page = 1) => {
  const requestOptions: any = {
    method: "GET",
    redirect: "follow",
  };

  return await fetch(
    `${API_PATH}/quoc-gia/${country}.json?slug=${country}&page=${page}`,
    requestOptions,
  )
    .then((response) => response.json())
    .then((result) => handleResponse(result))
    .catch((error) => console.log("error", error));
};

export const getListMoviesByYear = async (year: string, page = 1) => {
  const requestOptions: any = {
    method: "GET",
    redirect: "follow",
  };

  return await fetch(
    `${API_PATH}/danh-sach/${CATAGORIES.PHIM_MOI}.json?slug=${CATAGORIES.PHIM_MOI}&page=${page}&&year=${year}`,
    requestOptions,
  )
    .then((response) => response.json())
    .then((result) => handleResponse(result))
    .catch((error) => console.log("error", error));
};

export const searchMovies = async (keyword: string, page = 1) => {
  const requestOptions: any = {
    method: "GET",
    redirect: "follow",
  };

  return await fetch(
    `${API_PATH}/tim-kiem.json?keyword=${keyword}&page=${page}`,
    requestOptions,
  )
    .then((response) => response.json())
    .then((result) => handleResponse(result))
    .catch((error) => console.log("error", error));
};

export const getMovieDetail = async (id: string) => {
  const requestOptions: any = {
    method: "GET",
    redirect: "follow",
  };

  return await fetch(`${API_PATH}/phim/${id}.json?slug=${id}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};
