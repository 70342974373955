import { MOVIE_CATAGORIES, getMovieCatagoryTitle } from "../consts/CATAGORIES";
import { COUNTRIES, getCountryTitle } from "../consts/COUNTRIES";
import { CLink as Link } from "./CLink";
import { Logo } from "./Logo";

export const Footer = () => {
  return (
    <footer className=" max-w-screen-xl w-full border-t-4 border-blue-500 mt-4">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 py-8 gap-4">
        <div className="col-span-2 md:col-span-3 lg:col-span-2">
          <Logo />
          <p className="mt-2">
            Trang xem phim Online với giao diện mới được bố trí và thiết kế thân
            thiện với người dùng. Nguồn phim được tổng hợp từ các website lớn
            với đa dạng các đầu phim và thể loại vô cùng phong phú.
          </p>
        </div>
        <div>
          <h2 className="font-bold">Phim mới</h2>
          {[
            MOVIE_CATAGORIES.KHOA_HOC,
            MOVIE_CATAGORIES.KINH_DI,
            MOVIE_CATAGORIES.HINH_SU,
            MOVIE_CATAGORIES.HANH_DONG,
          ].map((catagory) => (
            <Link
              className="block"
              key={catagory}
              reloadDocument
              to={`/the-loai/${catagory}`}
            >
              Phim {getMovieCatagoryTitle(catagory)}
            </Link>
          ))}
        </div>
        <div>
          <h2 className="font-bold">Phim hay</h2>
          {[
            COUNTRIES.AU_MY,
            COUNTRIES.HAN_QUOC,
            COUNTRIES.TRUNG_QUOC,
            COUNTRIES.NHAT_BAN,
            COUNTRIES.THAI_LAN,
          ].map((country) => (
            <Link
              className="block"
              key={country}
              reloadDocument
              to={`/quoc-gia/${country}`}
            >
              Phim {getCountryTitle(country)}
            </Link>
          ))}
        </div>
        <div>
          <h2 className="font-bold">Thông tin</h2>
          <p>Giới thiệu</p>
          <p>Liên hệ chúng tôi</p>
          <p>Điều khoản sử dụng</p>
          <p>Chính sách riêng tư</p>
          <p>Khiếu nại bản quyền</p>
        </div>
      </div>
      <p className="border-t-2 border-blue-500 pt-4">&copy; Phim</p>
    </footer>
  );
};
