import { useEffect, useState } from "react";
import adsData from "../../ads.json";
import { AdsData } from "../../type/type";

export const AdsVideo = ({ onSkip }: { onSkip: () => void }) => {
  const [countdown, setCountdown] = useState(5);
  const [skipEnabled, setSkipEnabled] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoVisible, setVideoVisible] = useState(true);
  const [buttonVisible, setButtonVisible] = useState(true);

  const getAdsData: AdsData = {
    "Ads Video": adsData["Ads Video"],
  };

  const handleSkip = () => {
    if (skipEnabled) {
      setButtonVisible(false);
      setVideoVisible(false);
      onSkip();
    }
  };

  useEffect(() => {
    if (videoPlaying) {
      const interval = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);

      if (countdown === 0) {
        clearInterval(interval);
        setSkipEnabled(true);
      }

      return () => clearInterval(interval);
    }
  }, [countdown, onSkip, videoPlaying]);

  return (
    <div className="bg-black relative">
      {videoVisible && (
        <a
          href="https://www.epscore3.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <video
            autoPlay
            className="my-4 h-[520px]"
            controls
            onEnded={() => {
              if (skipEnabled) {
                setVideoVisible(false);
                onSkip();
              }
            }}
            onPause={() => setVideoPlaying(false)}
            onPlay={() => setVideoPlaying(true)}
            width="100%"
          >
            {Object.keys(getAdsData).map((key, index) => (
              <source
                key={index}
                src={getAdsData[key].bannerImg.url}
                type="video/mp4"
              />
            ))}
          </video>
        </a>
      )}
      {countdown > 0 && (
        <button
          className="absolute top-0 right-0 m-4 bg-white text-black px-4 py-2 rounded-md"
          disabled
        >
          Skip in {countdown}s
        </button>
      )}
      {buttonVisible && countdown === 0 && (
        <button
          className={`absolute top-0 right-0 m-4 bg-white text-black px-4 py-2 rounded-md ${skipEnabled ? "" : "cursor-not-allowed"}`}
          disabled={!skipEnabled}
          onClick={handleSkip}
        >
          Skip Ad
        </button>
      )}
    </div>
  );
};
