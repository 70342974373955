import Hls from "hls.js";

function processToCutAds(data: any) {
  const lines = data.split("\n");
  const discontinuity = [];
  const times = [0];
  let gap = 0;
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].includes("#EXTINF")) {
      times[gap] += parseFloat(lines[i].split(":")[1].split(",")[0]);
    }
    if (lines[i].includes("#EXT-X-DISCONTINUITY")) {
      discontinuity.push(i);
      gap++;
      times.push(0);
    }
  }

  // Get ads duration
  let adsDuration = 0;
  let time = 0;
  for (let i = 0; i < times.length; i++) {
    if (time > 570) {
      adsDuration = times[i];
      break;
    } else {
      time += times[i];
    }
  }

  // get index of gaps
  const idxGaps = [];
  for (let i = 0; i < times.length; i++) {
    if (times[i] === adsDuration) {
      idxGaps.push(i);
    }
  }

  for (let i = idxGaps.length - 1; i >= 0; i--) {
    const [start, end] = [
      discontinuity[idxGaps[i] - 1],
      discontinuity[idxGaps[i]],
    ];
    lines.splice(start, end - start + 1);
  }

  return lines.join("\n");
}

class pLoader extends Hls.DefaultConfig.loader {
  constructor(config: any) {
    super(config);
    const load = this.load.bind(this);
    this.load = function (context: any, config: any, callbacks: any) {
      if (context.type == "level") {
        const onSuccess = callbacks.onSuccess;
        callbacks.onSuccess = function (
          response: any,
          stats: any,
          context: any,
        ) {
          response.data = processToCutAds(response.data);
          onSuccess(response, stats, context);
        };
      }
      load(context, config, callbacks);
    };
  }
}

export default pLoader;
