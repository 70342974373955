import { APP_DOMAIN_CDN_IMAGE } from "../configs";
import { CLink as Link } from "./CLink";

export const MovieCard = ({ data }: any) => {
  if (!data) return;
  return (
    <Link reloadDocument to={`/phim/${data.slug}`}>
      <div className="relative mb-1">
        <img
          alt={data.name}
          className="h-36 md:h-48 w-full object-cover object-top bg-slate-500"
          loading="lazy"
          src={`${APP_DOMAIN_CDN_IMAGE}/uploads/movies/${data.thumb_url}`}
        />
        <div className="absolute bottom-1 left-1 text-xs bg-blue-500 text-white px-1 uppercase font-bold">
          <p>
            {data.lang
              ? `${data.quality} ${data.episode_current} ${data.lang}`
              : data.year}
          </p>
        </div>
      </div>
      <div className="card-body text-sm">
        <h2 className="font-bold truncate">{data.name}</h2>
        <h3 className="truncate">{data.origin_name}</h3>
      </div>
    </Link>
  );
};

export const MovieCardDetail = ({ movie }: any) => {
  return (
    <div className="flex flex-col bg-white md:flex-row my-4">
      <img
        alt={movie?.origin_name}
        className="object-cover w-full h-96 object-top md:hidden"
        loading="lazy"
        src={`${APP_DOMAIN_CDN_IMAGE}/uploads/movies/${movie?.poster_url}`}
      />
      <img
        alt=""
        className="object-cover h-auto w-48 object-top hidden md:block"
        loading="lazy"
        src={`${APP_DOMAIN_CDN_IMAGE}/uploads/movies/${movie?.thumb_url}`}
      />
      <div className="flex flex-col justify-between p-4 leading-normal">
        <h1 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          {movie?.name}
        </h1>
        <h2 className="mb-2 text-lg tracking-tight text-gray-900">
          {movie?.origin_name}
        </h2>
        <p className="mb-2 text-gray-700">
          {movie?.quality} - {movie?.episode_total} Tập - {movie?.time} -{" "}
          {movie?.lang}
        </p>
        <p
          className="mb-3 font-normal text-gray-700"
          dangerouslySetInnerHTML={{ __html: movie?.content }}
        ></p>
        <div>
          {Boolean(movie?.actor?.filter(Boolean).length) && (
            <p>
              <b>Diễn viên:</b> {movie?.actor?.join(", ")}
            </p>
          )}
          <p>
            <b>Thể loại:</b>{" "}
            {movie?.category.map((c: any) => (
              <Link
                className="me-2 mb-2"
                key={c.slug}
                reloadDocument
                to={`/the-loai/${c.slug}`}
              >
                {c.name}
              </Link>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
};
