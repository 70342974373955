import { useEffect, useState } from "react";
import adsData from "../../ads.json";
import { AdsData } from "../../type/type";
import { CLink } from "../CLink";

export const AdsPopup = () => {
  const [isAdsVisible, setIsAdsVisible] = useState(false);
  const [isButtonClickable, setIsButtonClickable] = useState(false);
  const [countdown, setCountdown] = useState(5);

  const getAdsData: AdsData = {
    "Popup Banner": adsData["Popup Banner"],
  };

  const checkAds = () => {
    const lastClosed = sessionStorage.getItem("lastClosed");
    if (!lastClosed || Date.now() - Number(lastClosed) > 900000) {
      setIsAdsVisible(true);
      setIsButtonClickable(false);
      setCountdown(5);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          setIsButtonClickable(true);
          clearInterval(timer);
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // This will clear the timer when the component unmounts
  }, [countdown]);

  useEffect(() => {
    checkAds();
    const timer = setInterval(() => {
      checkAds();
    }, 60000);

    return () => clearInterval(timer); // This will clear the timer when the component unmounts
  }, []);

  const closeAd = () => {
    setIsAdsVisible(false);
    sessionStorage.setItem("lastClosed", String(Date.now()));
  };

  return (
    <div
      className={`${isAdsVisible ? "fixed" : "hidden"} h-screen w-screen bg-slate-900/80 top-0 z-50`}
    >
      <div className=" mt-40">
        <button
          className="block m-auto bg-slate-900 text-white px-2"
          disabled={!isButtonClickable}
          onClick={closeAd}
        >
          {isButtonClickable ? (
            <CLink to="#">Đóng quảng cáo (x)</CLink>
          ) : (
            <p>Đóng quảng cáo ({countdown}s)</p>
          )}
        </button>
        <div className="grid justify-items-center">
          <div className="w-full md:w-[600px] md:h-[400px] bg-gray-300 flex items-center justify-center font-extrabold text-gray-500">
            {Object.keys(getAdsData).map((key, index) => (
              <CLink target="_blank" to={getAdsData[key].adsUrl.url}>
                <img
                  key={index}
                  loading="eager"
                  src={getAdsData[key].bannerImg.url}
                />
              </CLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
