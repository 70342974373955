import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./index.css";
import { App } from "./App";
import { Category } from "./pages/Category";
import { Country } from "./pages/Country";
import Home from "./pages/Home";
import { MoiveDetails } from "./pages/MovieDetails";
import { SearchPage } from "./pages/Search";
import { WatchMovie } from "./pages/WatchMovie";
import { MoviesByYear } from "./pages/Year";

Sentry.init({
  dsn: "https://75ba40fff22bd0a513a791a3f1371e8f@o4507185522475008.ingest.us.sentry.io/4507252779974656",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/mephim247\.com/],
  // Performance Monitoring
  tracesSampleRate: 0, //  Capture 100% of the transactions
});

const router = createBrowserRouter([
  {
    children: [
      {
        element: <Home />,
        path: "/",
      },
      {
        element: <MoiveDetails />,
        path: "/phim/:id",
      },
      {
        element: <WatchMovie />,
        path: "/xem-phim/:id/:episode",
      },
      {
        element: <SearchPage />,
        path: "/tim-kiem/:keyword",
      },
      {
        element: <Category />,
        path: "/the-loai/:category",
      },
      {
        element: <Country />,
        path: "/quoc-gia/:country",
      },
      {
        element: <MoviesByYear />,
        path: "/nam/:year",
      },
      {
        element: <p>404 Not Found</p>,
        path: "/404",
      },
    ],
    element: <App />,
    path: "/",
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
