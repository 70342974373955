import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../components/Breadcrumb";
import { Carousel } from "../components/Carousel";
import { FBComment } from "../components/FBComment";
import { ListEpisodes } from "../components/ListEpisodes";
import { Loading } from "../components/Loading";
import { MovieCardDetail } from "../components/MovieCard";
import { APP_DOMAIN_CDN_IMAGE } from "../configs";
import { CATAGORIES } from "../consts/CATAGORIES";
import { getMovieDetail } from "../services/base";

export const MoiveDetails = () => {
  const { id } = useParams();
  const [movie, setMovie] = useState<any>({});
  const [seoOnPage, setSeoOnPage] = useState<any>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMovieDetail(id || "")
      .then((data) => {
        const tempMovie = data?.pageProps?.data?.item;
        setMovie(tempMovie);
        const tempSEO = data?.pageProps?.data?.seoOnPage?.seoSchema;
        setSeoOnPage({
          "@context": "https://schema.org",
          "@type": "VideoObject",
          contentUrl: window.location.href,
          dateModified: tempSEO?.dateModified,
          datePublished: tempSEO?.datePublished,
          description: data?.pageProps?.data?.seoOnPage?.descriptionHead,
          name: data?.pageProps?.data?.seoOnPage?.titleHead,
          thumbnailUrl: `${APP_DOMAIN_CDN_IMAGE}/uploads/movies/${tempMovie?.thumb_url}`,
          uploadDate: tempSEO?.dateCreated,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  if (!movie?.status) {
    return <div>Not Found</div>;
  }

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(seoOnPage)}</script>
        <meta
          content={`${APP_DOMAIN_CDN_IMAGE}/uploads/movies/${movie?.thumb_url}`}
          property="og:image"
        />
        <title>
          {seoOnPage?.name ||
            "Mê Phim 247 - Xem phim chiếu rạp, điện ảnh miễn phí có phụ đề"}
        </title>
        <meta
          content={seoOnPage?.description || movie?.origin_name}
          name="description"
        />
        <link href={window.location.href} rel="canonical" />
      </Helmet>
      <Breadcrumb current={movie?.name} />
      <MovieCardDetail movie={movie} />
      <ListEpisodes episodes={movie?.episodes} id={id} />
      <div className="my-8">
        <Carousel catagory={CATAGORIES.PHIM_MOI} />
      </div>
      <FBComment />
    </div>
  );
};
