export const CATAGORIES = {
  HOAT_HINH: "hoat-hinh",
  PHIM_BO: "phim-bo",
  PHIM_LE: "phim-le",
  PHIM_MOI: "phim-moi",
  TV_SHOW: "tv-shows",
  getTitle: (catagory: string) => {
    switch (catagory) {
      case CATAGORIES.HOAT_HINH:
        return "Hoạt hình";
      case CATAGORIES.PHIM_BO:
        return "Phim bộ";
      case CATAGORIES.PHIM_LE:
        return "Phim lẻ";
      case CATAGORIES.PHIM_MOI:
        return "Phim mới";
      case CATAGORIES.TV_SHOW:
        return "TV Shows";
      default:
        return "";
    }
  },
};

export const MOVIE_CATAGORIES = {
  AM_NHAC: "am-nhac",
  BI_AN: "bi-an",
  CHIEN_TRANH: "chien-tranh",
  CHINH_KICH: "chinh-kich",
  CO_TRANG: "co-trang",
  // BL: "bl",
  GIA_DINH: "gia-dinh",
  HAI_HUOC: "hai-huoc",
  HANH_DONG: "hanh-dong",
  HINH_SU: "hinh-su",
  KHOA_HOC: "khoa-hoc",
  KINH_DI: "kinh-di",
  // KICH_TINH: "kich-tinh",
  PHIEU_LUU: "phieu-luu",
  TAM_LY: "tam-ly",
  // NETFLIX: "netflix",
  THAN_THOAI: "than-thoai",
  TINH_CAM: "tinh-cam",
  VIEN_TUONG: "vien-tuong",
  VO_THUAT: "vo-thuat",
};

export const getMovieCatagoryTitle = (catagory: string) => {
  switch (catagory) {
    case MOVIE_CATAGORIES.AM_NHAC:
      return "Âm Nhạc";
    case MOVIE_CATAGORIES.BI_AN:
      return "Bí Ẩn";
    case MOVIE_CATAGORIES.CHIEN_TRANH:
      return "Chiến Tranh";
    case MOVIE_CATAGORIES.CHINH_KICH:
      return "Chính Kịch";
    case MOVIE_CATAGORIES.CO_TRANG:
      return "Cổ Trang";
    case MOVIE_CATAGORIES.GIA_DINH:
      return "Gia Đình";
    case MOVIE_CATAGORIES.HAI_HUOC:
      return "Hài Hước";
    case MOVIE_CATAGORIES.HANH_DONG:
      return "Hành Động";
    case MOVIE_CATAGORIES.HINH_SU:
      return "Hình Sự";
    case MOVIE_CATAGORIES.KHOA_HOC:
      return "Khoa Học";
    case MOVIE_CATAGORIES.KINH_DI:
      return "Kinh Dị";
    case MOVIE_CATAGORIES.PHIEU_LUU:
      return "Phiêu Lưu";
    case MOVIE_CATAGORIES.TAM_LY:
      return "Tâm Lý";
    case MOVIE_CATAGORIES.THAN_THOAI:
      return "Thần Thoại";
    case MOVIE_CATAGORIES.TINH_CAM:
      return "Tình Cảm";
    case MOVIE_CATAGORIES.VIEN_TUONG:
      return "Viễn Tưởng";
    case MOVIE_CATAGORIES.VO_THUAT:
      return "Võ Thuật";
    default:
      return "";
  }
};
